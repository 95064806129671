

















































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsListingCard',
})
export default class extends Vue {
    @Prop({
        default: false,
        required: false,
    }) private active!: boolean

    @Prop({
        default: 'medium',
        required: false,
    }) private padding!: string

    @Prop({
        default: 'white',
        required: false,
    }) private aspect!: string

    get hasImageSlot () {
        return !!this.$slots.image
    }
}
