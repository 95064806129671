
















































































































































































































































































































































































import { AppModule } from '@/store/modules/app'
import { get } from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import VsImage from '@/modules/landingpages/components/VsImage/Index.vue'
import { UserModule } from '@/store/modules/user'
import {
    CampaignStatusEnum, ICampaignFromGetAll,
} from '@/api/consoleApi/dto/campaigns.dto'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsLink from '@advision/vision/src/components/utilities/VsLink/Index.vue'
import { formatUnixDate } from '@/utils/formatter'

@Component({
    name: 'VsCampaignCard',
    components: {
        VsListingCard,
        VsLink,
        VsDropdownButton,
        VsImage,
    },
})
export default class extends Vue {
    @Prop({
        default: null,
        required: true,
    }) private campaign!: ICampaignFromGetAll

    @Prop({
        default: null,
        required: false,
    }) private value!: string[]

    @Prop({
        default: false,
        required: false,
        type: Boolean,
    }) private hideActions!: boolean

    get message () {
        if (this.campaign.email) return true
        if (this.campaign.sms) return true
        return false
    }

    get messageId () {
        if (this.campaign.email && this.campaign.email.data.id) {
            return this.campaign.email.data.id
        }
        if (this.campaign.sms && this.campaign.sms.data.id) {
            return this.campaign.sms.data.id
        }
    }

    get sentOrScheduledTime () {
        if (!this.campaign?.status) return ''
        let date: any = ''
        switch (this.campaign.status) {
            case 'Paused':
            case 'Sending':
            case 'Failed':
            case 'Sent':
                date = this.campaign.sent_start
                break
            case 'Ready':
                if (this.campaign.schedule_type === 'Immediate') {
                    return this.$t('campaigns.campaignCard.scheduleTypes.immediate')
                } else if (this.campaign.schedule_type === 'Recursive') {
                    return this.$t('campaigns.campaignCard.scheduleTypes.ricorsive')
                } else {
                    if (this.campaign.scheduled) {
                        date = formatUnixDate(this.campaign.scheduled, 'DD MMMM YYYY HH:mm')
                    }
                }
                break
            case 'Pending Approval':
            case 'Draft':
            default: return date
        }
        return this.$options?.filters?.formatDate(date)
    }

    get userRules () {
        return UserModule.user.configuration.rules
    }

    get canSplitTest () {
        return this.userRules.abTest
    }

    get canExportHtml () {
        return this.userRules.exportHtml
    }

    get canBooster () {
        return this.userRules.booster
    }

    get canCreateBooster () {
        return this.isEmail && this.campaign.status === 'Sent' && this.campaign.type !== 'Booster'
    }

    get is4Dem () {
        return get(AppModule.consoleConf, 'is4Dem', false)
    }

    get universityUrl () {
        return AppModule.universityUrl
    }

    get isEmail () {
        return (this.campaign.content_type === 'email')
    }

    get isSms () {
        return (this.campaign.content_type === 'sms')
    }

    get isAbSplit () {
        return this.campaign.content_type === 'split_test'
    }

    get badgeStatusAspect () {
        if (!this.campaign?.status) return ''
        switch (this.campaign?.status || null) {
            case CampaignStatusEnum.Ready:
                return 'info'
            case CampaignStatusEnum.Sending:
                return 'primary'
            case CampaignStatusEnum.Sent:
                return 'success'
            case CampaignStatusEnum.Failed:
                return 'alert'
            case CampaignStatusEnum.Paused:
                return 'warning'
            case CampaignStatusEnum['Pending Approval']:
            case CampaignStatusEnum.Draft:
            default:
                return 'grey'
        }
    }

    get badgeStatusIcon () {
        if (!this.campaign?.status) return ''
        switch (this.campaign?.status || null) {
            case CampaignStatusEnum.Ready:
                return 'clock'
            case CampaignStatusEnum.Sending:
                return 'outgoing'
            case CampaignStatusEnum.Paused:
                return 'pause'
            case CampaignStatusEnum['Pending Approval']:
                return 'clock'
            case CampaignStatusEnum.Sent:
                return 'double-check'
            case CampaignStatusEnum.Failed:
                return 'error'
            case CampaignStatusEnum.Draft:
                return 'edit'
        }
    }

    get campaignType () {
        if (this.campaign.content_type === 'split_test') return 'A/B test'
        if (this.campaign.email) {
            if (this.campaign.email.data.project_id) return 'drag&drop'
            return 'html'
        }
        return 'nd'
    }

    get defaultImage () {
        return this.campaign.content_type === 'email' || this.campaign.content_type === 'split_test' ? require('@/assets/img/campaigns/email-placeholder.png') : require('@/assets/img/campaigns/sms-placeholder.png')
    }

    get previewImage () {
        return this.campaign.thumbnailUrl ? this.campaign.thumbnailUrl + `?t=${Date.now()}` : this.defaultImage
    }

    private redirectToUniversity () {
        window.open(`${this.universityUrl}/article/337-la-mia-campagna-e-stata-messa-in-pausa`, '_blank')
    }

    private redirectToCampaign (campaignId: string) {
        this.$router.push({
            name: 'campaignEdit',
            params: {
                campaignId,
            },
        })
    }
}
